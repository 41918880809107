
export class ProjectGroup {
  name: string;
  type: ProjectType;
  projects: Project[];
}

export class Project {
  name: string;
  environments: ProjectEnvironment[];
}

export class ProjectEnvironment {
  url: string;
  environment: Environment;
}

export enum ProjectType {
  GAMING = 'GAMING',
  PROJECT = 'PROJECT',
  WEBSITE = 'WEBSITE',
  SERVER = 'SERVER',
  TOOLS = 'TOOLS'
}

export enum Environment {
  LOCAL = 'LOCAL',
  DEV = 'DEV',
  PREPROD = 'PREPROD',
  PROD = 'PROD',
  WINDOWS = 'WINDOWS',
  MAC = 'MAC',
  LINUX = 'LINUX',
  STEAM = 'STEAM'
}
