import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ProjectComponent } from './projet/project.component';
import { AppErrorComponent } from './app-error/app-error.component';

import { AppRoutingModule } from './app-routing.module';
import { ToolbarComponent } from './toolbar/toolbar.component';

import { AppPropertiesService } from './core/services/app-properties.service';
import { ProjectHttpService } from './projet/services/project.http.service';
import {ProjectService} from './projet/services/project.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    ProjectComponent,
    AppErrorComponent,
    ToolbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    AppPropertiesService,
    ProjectHttpService,
    ProjectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
