
export class EnvironmentParams {
  platform: string;
  exploitBackSpringApi: string;
  exploitBackSymfonyApi: string;
}

export class Configurations {
  environment: EnvironmentParams;
}
