<div class="main">
  <app-toolbar></app-toolbar>
  <div style="text-align:center">
    <h1>
      Welcome to {{ title }}!
    </h1>
  </div>
  <div class="main__content">
    <router-outlet class="main__outlet"></router-outlet>
  </div>
</div>
