import { Injectable } from '@angular/core';
import { EnvironmentParams } from '../config/configuration';

@Injectable({
  providedIn: 'root'
})
export class AppPropertiesService {

  private environment: EnvironmentParams;

  constructor() {
    this.environment = new EnvironmentParams();
  }

  public setEnvironmentParams(environment: EnvironmentParams): void {
    this.environment = environment;
  }

  public getEnvironmentParams(): EnvironmentParams {
    return this.environment;
  }

  // any because -> can be string, number or object
  public getEnvironmentParam(key: string): any {
    return this.environment[key];
  }
}

