import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProjectComponent } from './projet/project.component';
import { AppErrorComponent } from './app-error/app-error.component';

// setup allowed routes and associated components
const appRoutes: Routes = [
  {
    path: 'projets',
    component: ProjectComponent
  },
  {
    path: 'app-error',
    component: AppErrorComponent
  },
  {
    path: '**',
    redirectTo: 'projets'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        useHash: true,
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: []
})
export class AppRoutingModule {
}
