<p>projet works!</p>

<div>
  <div *ngFor="let projectGroup of projectGroups">
    <h2>{{ projectGroup.name }}</h2>
    <div *ngFor="let project of projectGroup.projects">
      <p>{{ project.name }}</p>
    </div>
  </div>
</div>
