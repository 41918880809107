import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppPropertiesService } from '../../core/services/app-properties.service';
import {Observable, of} from 'rxjs';
import {Environment, ProjectGroup, ProjectType} from '../models/project.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectHttpService {

  private exploitBackIkoulaUrl: string;
  private exploitBackPlanethosterUrl: string;


  constructor(private httpClient: HttpClient, appProperties: AppPropertiesService) {
    this.exploitBackIkoulaUrl = appProperties.getEnvironmentParam('exploitBackSpringApi');
    this.exploitBackPlanethosterUrl = appProperties.getEnvironmentParam('exploitBackSymfonyApi');
  }

  public getIkoulaProjects(): Observable<ProjectGroup[]> {
    console.log('getIkoulaProjects');
    /*const url = `${this.exploitBackIkoulaUrl}/projects`;
    return this.httpClient.get<ProjectGroup[]>(url);*/
    return this.getIkoulaProjectsMock();
  }

  public getPlanetHosterProjects(): Observable<ProjectGroup[]> {
    console.log('getPlanetHosterProjects');
    /*const url = `${this.exploitBackPlanethosterUrl}/projects`;
    return this.httpClient.get<ProjectGroup[]>(url);*/
    return this.getPlanetHosterProjectsMock();
  }

  private getIkoulaProjectsMock(): Observable<ProjectGroup[]> {
    const projects = [
      {
        name: 'WEBSITE',
        type: ProjectType.WEBSITE,
        projects: [
          {
            name: 'pokemon',
            environments : [
              {
                environment: Environment.LOCAL,
                url: 'http://localhost:8080'
              },
              {
                environment: Environment.DEV,
                url: 'http://pokemon-dev.fiarimike.fr/pokemon-dev'
              }
            ]
          },
          {
            name: 'HoMe Menu',
            environments : [
              {
                environment: Environment.DEV,
                url: 'http://homemenus.fiarimike.fr'
              }
            ]
          }
        ]
      },
      {
        name: 'GAMING',
        type: ProjectType.GAMING,
        projects: [
          {
            name: 'Pokemon',
            environments: [
              {
                environment: Environment.WINDOWS,
                url: 'http://pokemon-dev.fiarimike.fr/pokemon-dev'
              }
            ]
          }
        ]
      }
    ];
    return of (projects);
  }

  private getPlanetHosterProjectsMock(): Observable<ProjectGroup[]> {
    const projects = [
      {
        name: 'WEBSITE',
        type: ProjectType.WEBSITE,
        projects: [
          {
            name: 'HoMe Menu',
            type: ProjectType.WEBSITE,
            environments : [
              {
                environment: Environment.PROD,
                url: 'http://homemenus.mikefiari.fr'
              }
            ]
          }
        ]
      }
    ];
    return of (projects);
  }
}
