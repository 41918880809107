import { Injectable } from '@angular/core';
import {ProjectGroup} from '../models/project.model';
import {ProjectHttpService} from './project.http.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  private projetcsSubject$ = new BehaviorSubject<ProjectGroup[]>(null);

  constructor(private projectHttpService: ProjectHttpService) {
  }

  public getProjects(): Observable<ProjectGroup[]> {
    return this.projetcsSubject$.asObservable();
  }

  public loadProjects(): void {
    this.projectHttpService.getIkoulaProjects().subscribe(ikoulaProjects => {
      this.projectHttpService.getPlanetHosterProjects().subscribe(phProjects => {
        console.log(ikoulaProjects);
        console.log(phProjects);
        this.mergeProjects(ikoulaProjects, phProjects);
      });
    });
  }

  private mergeProjects(ikoulaProjects: ProjectGroup[], phProjects: ProjectGroup[]): void {
    this.projetcsSubject$.next(ikoulaProjects);
  }
}
