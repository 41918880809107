import { Component, OnInit } from '@angular/core';
import {ProjectService} from './services/project.service';
import {ProjectGroup} from './models/project.model';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  public projectGroups: ProjectGroup[];

  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
    this.projectService.getProjects().subscribe(projects => {
      console.log(projects);
      this.projectGroups = projects;
    });
    this.projectService.loadProjects();
  }

}
